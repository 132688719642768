// Start Generation Here
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_RENDER_ENDPOINT}v1/`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});


export const getAccessToken = () => {
    let token;
    if (typeof window !== 'undefined') {
        // Perform localStorage action
        token = localStorage.getItem("credentials");
        if (token) {
            return JSON.parse(token ?? "{}")?.accessToken
        }
        return null
    }


}


// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // You can add token or other headers here if needed

        if (!config.url?.includes('/auth/')) {
            config.headers["Authorization"] = `bearer ${getAccessToken()}`;

        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Handle errors globally
        return Promise.reject(error);
    }
);

export default axiosInstance;
