import { Link } from "react-router-dom";

// assets
import FOOTBALL_PITCH from "../../../assets/sport-grass.jpeg";
import LOGO from "../../../assets/logo.svg";
// import TERMS_OF_USE_MOBILE_HEADER from "../../../assets/termsOfUse/terms_of_use_mobile_header.svg";
import Header from "src/common/components/Header";

const TermsOFUse = () => {
  return (
    <div className="w-full flex flex-col relative px-[16px] bg-[#444444] min-h-screen">
      <div
        className="w-full lg:h-[769px] h-[682px] absolute top-0 left-0"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${FOOTBALL_PITCH})`,
        }}
      >
        <Header />

        <div className="flex items-center justify-center flex-col md:mt-[1px] mt-[40px]">
          <img className="w-36" src={LOGO} alt="LOGO ICON" />
          <p className="font-medium text-xl leading-7 DMSans">
            27Prediction league
          </p>

          <p className="anton  md:md:text-[44px] text-2xl md:text-2xl leading- leading-7[56px] lg:mt-[42px] mt-[24px]">
            Terms of use
          </p>
        </div>
      </div>

      <div className="lg:mt-[219px] mt-[225px] w-full lg:px-[100px] md:px-[100px] py-[40px] px-[10px] bg-[#00251C] z-10 flex flex-col md:gap-[64px] gap-[22px]">
        <p className="font-[inter]  md:text-[17px] text-sm md:leading-[36px] leading-6">
          Welcome to our platform! By using our service, you agree to the
          following Terms of Use. Please read this document carefully to
          understand your rights and responsibilities.
        </p>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Introduction
          </p>

          <p className="font-[inter]  md:text-[17px] text-sm md:leading-[36px] leading-6">
            This platform allows users to predict match outcomes and analyze
            sports data. By registering and using the platform, you agree to all
            the terms outlined in this document.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Account Creation
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter]  md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Users must provide accurate and complete information during
              registration.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Each user may only have one account. Accounts with false
              information may be suspended or deleted.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              You are responsible for maintaining the confidentiality of your
              account and password.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Platform Usage
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Users are permitted to use the platform in accordance with its
              intended purpose. Any unauthorized use may result in access being
              denied.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Content, including comments and analyses, must comply with the
              platform's code of conduct and must not be offensive,
              inappropriate, or unlawful.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Rights and Responsibilities of the Platform Owner
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              We reserve the right to modify or discontinue platform
              functionalities at any time.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              We may suspend or terminate accounts that violate the rules.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              We reserve the right to moderate and remove content that does not
              comply with the rules.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Limitation of Liability
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Our platform is intended solely for entertainment and educational
              purposes. We do not guarantee the accuracy of predictions or
              success in fantasy games.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              We are not responsible for technical errors, service
              interruptions, or issues with data obtained from third parties.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Privacy and Data Protection
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Your data is collected and used in accordance with our Privacy
              Policy. We ensure the highest level of data security in compliance
              with applicable laws.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Changes to the Terms of Use
          </p>
          <ul className="list-disc list-inside">
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              These terms may be modified at any time. Users will be notified of
              changes via email or announcements on the platform.
            </li>
            <li
              className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6"
              style={{
                textIndent: "-1.4em",
                paddingLeft: "2em",
              }}
            >
              Continued use of the platform after changes implies your agreement
              with the new terms.
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <p className="anton  md:text-[44px] text-2xl md:leading-[56px] leading-7">
            Contact
          </p>
          <ul className="list-disc list-inside">
            <p className="font-[inter] md:text-[17px] text-sm md:leading-[36px] leading-6">
              If you have any questions regarding these Terms of Use, please{" "}
              <a href="mailto:27community27@gmail.com" className="underline">
                27community27@gmail.com
              </a>
            </p>
          </ul>
        </div>
      </div>

      <div className="md:h-[68px] h-[52px] flex justify-center items-center">
        <p className="font-semibold font-[inter] lg:text-[28px] text-[17px]">
          Last modified: January 2025
        </p>
      </div>

      <div className="flex flex-col h-[160px] bg-[#00251C] relative">
        <div className="h-full w-full md:px-[100px] px-[10px] flex items-center justify-between">
          <Link
            to="https://www.instagram.com/27_community"
            target="_blank"
            className="anton text-[16px] font-normal leading-[24px] text-left"
          >
            INSTAGRAM
          </Link>
          <Link
            to="https://x.com/S27community"
            target="_blank"
            className="anton text-[16px] font-normal leading-[24px] text-left"
          >
            X
          </Link>
          <Link
            to="https://discord.gg/27community"
            target="_blank"
            className="anton text-[16px] font-normal leading-[24px] text-left"
          >
            DISCORD
          </Link>

          <Link
            to="https://t.me/+tXIkY-sTMmJhM2M0"
            className="anton text-[16px] font-normal leading-[24px] text-left"
          >
            TELEGRAM
          </Link>
        </div>

        <div className="w-full h-[48px] md:px-[40px] px-[10px] flex justify-between items-center bg-[#33535D99]">
          <p className="font-[inter] md:text-[14px] text-[10px] leading-[24px] text-center">
            © 2025 27Prediction League. All rights reserved.
          </p>

          <Link
            to="/terms-of-use"
            className="font-[inter] md:text-[14px] text-[10px] leading-[24px] text-center"
          >
            Terms of Use
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsOFUse;
