import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";
import { TbLoader } from "react-icons/tb";
import { useLocation, useParams } from "react-router";
import Assets from "src/assets";
import Button from "src/common/components/Button";
import Comment from "src/common/components/Comment";
import CreateComment from "src/common/components/CreateComment";
import { Icons } from "src/common/components/Icons";
import LineUpComponent from "src/common/components/LineUpComponent";
import MatchStatistics from "src/common/components/MatchStatistics";
import { Stat } from "src/common/components/StatisticCard";
import { useLocalStorage } from "src/common/controllers/localstorage";
import { cn, formatDateNative, formatTimeNative } from "src/lib/utils";
import { getMethod, postMethod } from "src/services/request-methods";
import { CommentProps, Head2Head } from "src/types";

const SingleMatchDetails = () => {
  const location = useLocation();
  let { id } = useParams();
  const [lineUp, setLineUp] = React.useState<any>();
  const [comment, setComment] = React.useState("");
  const [subComment, setSubComment] = React.useState("");
  const [parentCommentId, setParentCommentId] = React.useState<null | string>(
    null
  );
  const [toggle, setToggle] = React.useState(false);
  const [matchStatistics, setMatchStatistics] = React.useState<Stat[]>([]);
  const { isPending, data } = useQuery({
    queryKey: ["get-single-match", { route: `/match/${id}`, params: {} }],
    queryFn: getMethod,
    enabled: !!id,
  });

  const [likeObj, setLikeObj] = React.useState<any>({
    id: "",
    like: false,
  });
  const {
    data: lineUpData,
    isSuccess,
    isLoading: isLoadingLineUpData,
  } = useQuery({
    queryKey: [
      "get-lineup",
      {
        route: `/match/api/lineups/${id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  const injuryQuery = useQuery({
    queryKey: [
      "get-injuries",
      {
        route: `/match/api/injuries/${id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  const winningProbability = useQuery({
    queryKey: [
      "get-wining-probability",
      {
        route: `/match/api/predictions/${id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  const statisticData = useQuery({
    queryKey: [
      "get-lineup",
      {
        route: `/match/api/statistics/${id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  const comments = useQuery({
    queryKey: [
      "comments-query",
      {
        route: `/match/${id}/comments`,
        params: { page: 1, size: 10 },
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  React.useEffect(() => {
    if (statisticData.isSuccess && statisticData?.data[0]?.statistics) {
      const stats = statisticData.data[0].statistics.map(
        (stat: any, index: number) => ({
          type: stat.type,
          home: stat.value,
          away: statisticData.data[1].statistics[index]?.value ?? null,
        })
      );
      setMatchStatistics(stats);
    }
  }, [statisticData.isSuccess]);

  React.useEffect(() => {
    if (isSuccess) {
      const obj = { home: lineUpData[0], away: lineUpData[1] };
      setLineUp(obj);
    }
  }, [isSuccess]);

  const tabs = [
    {
      label: "Line Up",
      id: 1,
    },
    {
      label: "Stat",
      id: 2,
    },
  ];
  const [isActive, setIsActive] = React.useState<{
    id: number;
    label: string;
  }>(tabs[0]);

  const postCommentMutation = useMutation({
    mutationFn: postMethod,

    onError: (error: any) => {
      toast.error(error.response.data.message);
      console.log(error);
    },

    onSuccess: (data) => {
      toast.success(data.message);

      comments.refetch();
      setComment("");
      setSubComment("");
      setParentCommentId(null);
      setToggle(false);
    },
  });

  const postReactionMutation = useMutation({
    mutationFn: postMethod,

    onSuccess: (data) => {
      comments.refetch();
      setComment("");
      setParentCommentId(null);
      setToggle(false);
    },
  });

  const handleSend = () => {
    const payload = {
      matchId: id,
      content: parentCommentId ? subComment : comment,
      parentCommentId,
    };

    postCommentMutation.mutate({
      route: "/match/comments",
      payload,
    });
  };

  const { getFn } = useLocalStorage();

  const credentials = getFn("credentials");

  const { data: head2head, isLoading } = useQuery({
    queryKey: [
      "head-to-head",
      {
        route: `/match/api/head2head/${data?.details?.teams?.home?.id}/${data?.details?.teams?.away?.id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!data?.details?.teams?.home?.id,
  });

  if (isPending || isLoadingLineUpData || statisticData.isPending) {
    return (
      <div className="flex items-center justify-center">
        <TbLoader size={40} className="mr-1.5 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-[32px] flex flex-col items-center">
      <div className="grid grid-cols-2 py-4 w-full">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            onClick={() => setIsActive(tab)}
            className={cn(
              "col-span-1 !w-full border-none ",
              isActive.id === tab.id
                ? "!bg-white !text-primary "
                : "!bg-transparent !text-white"
            )}
          >
            {tab.label}
          </Button>
        ))}
      </div>

      <div className="p-4 h-[60px] relative w-full">
        <div className="size-full absolute top-0 left-0 bg-primary-foreground opacity-80">
          <img src={Assets.rough} alt="" className="size-full object-cover" />
        </div>

        {data?.date ? (
          <div className="relative flex items-center">
            <h5>{formatDateNative(data?.date)}</h5>
          </div>
        ) : null}
      </div>

      <div className="w-full  bg-secondary rounded-[8px]">
        <div className="py-16  space-y-[26px]">
          <div className="flex items-center justify-center gap-2">
            <Icons.Stadium />
            <p>{data?.details?.fixture?.venue?.name}</p>
          </div>

          {data?.details?.fixture?.refree ? (
            <div className="flex items-center justify-center gap-2">
              <Icons.Refree />
              <p>{data?.details?.fixture?.refree?.name}</p>
            </div>
          ) : null}

          <div className="flex items-center justify-center gap-x-12">
            <div className="flex flex-col gap-y-5">
              <div className="flex flex-col lg:flex-row items-center  justify-end gap-x-8  lg:w-[342px]">
                <img
                  src={data?.details?.teams?.home?.logo}
                  alt={data?.details?.teams?.home?.name}
                  className="lg:w-[98px] w-[70px]"
                />
                <h1 className="lg:text-5xl text-lg">
                  {data?.details?.teams?.home?.name}
                </h1>
              </div>
              {location?.state?.home ? (
                <div className="flex justify-center items-start gap-2">
                  {location.state?.home?.map((mp: string, index: number) => {
                    if (mp === "Win")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-primary-foreground md:border-[3px] border-[2px] border-primary-foreground"
                        ></span>
                      );
                    if (mp === "Draw")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-white md:border-[3px] border-[2px] border-muted-foreground"
                        ></span>
                      );
                    if (mp === "Lose")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-[#EF4444] md:border-[3px] border-[2px] border-[#EF4444]"
                        ></span>
                      );
                  })}
                </div>
              ) : null}
            </div>
            <div>
              {" "}
              <h4>vs</h4>
            </div>
            <div className="flex flex-col gap-y-5">
              <div className="flex flex-col lg:flex-row items-center justify-start gap-x-8 lg:w-[342px]">
                <img
                  src={data?.details?.teams?.away?.logo}
                  alt={data?.details?.teams?.away?.name}
                  className="lg:w-[98px] w-[70px]"
                />
                <h1 className="lg:text-5xl text-lg">
                  {data?.details?.teams?.away?.name}
                </h1>
              </div>
              {location?.state?.away ? (
                <div className="flex justify-center items-start gap-2">
                  {location.state?.away?.map((mp: string, index: number) => {
                    if (mp === "Win")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-primary-foreground md:border-[3px] border-[2px] border-primary-foreground"
                        ></span>
                      );
                    if (mp === "Draw")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-white md:border-[3px] border-[2px] border-muted-foreground"
                        ></span>
                      );
                    if (mp === "Lose")
                      return (
                        <span
                          key={index}
                          className="size-1.5 sm:size-[6px] md:size-3 rounded-full bg-[#EF4444] md:border-[3px] border-[2px] border-[#EF4444]"
                        ></span>
                      );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          {data?.date ? (
            <div className="flex items-center justify-center gap-2">
              <h5>{formatTimeNative(data?.date)}</h5>
            </div>
          ) : null}
        </div>

        {isActive.label === "Stat" ? (
          <MatchStatistics
            stats={matchStatistics}
            head2head={head2head?.sort(
              (a: Head2Head, b: Head2Head) =>
                new Date(b.fixture?.date ?? "").getTime() -
                new Date(a.fixture?.date ?? "").getTime()
            )}
            {...{ isLoading }}
            predictions={winningProbability.data?.predictions ?? null}
          />
        ) : lineUpData?.length === 2 ? (
          <LineUpComponent
            teams={lineUp as any}
            isPending={isLoadingLineUpData}
            injuries={injuryQuery.data ?? []}
          />
        ) : (
          <div className="w-full h-56 flex items-center justify-center">
            Match info will be available few hours to kick-off
          </div>
        )}
      </div>

      <div className="lg:w-[812px] flex flex-col pb-10 w-full">
        <h4>Comments</h4>

        <div className="space-y-[13px] mt-4">
          <CreateComment
            name={credentials?.username}
            rank={credentials?.rank}
            isLoading={
              postCommentMutation.isPending && parentCommentId === null
            }
            profile_picture={credentials?.profile_picture}
            {...{ setComment, handleSend, comment }}
          />
          {!comments.isPending && comments.isSuccess && (
            <div className="space-y-[20px]">
              {comments?.data?.comments.map((item: CommentProps) => (
                <Comment
                  profile_picture={credentials?.profile_picture}
                  key={item.id}
                  comment={subComment}
                  setComment={setSubComment}
                  name={credentials?.username}
                  rank={credentials?.rank}
                  isLoading={
                    postCommentMutation.isPending && parentCommentId !== null
                  }
                  {...{
                    item,
                    handleSend,
                    setParentCommentId,
                    toggle,
                    setToggle,
                    postReactionMutation,
                    likeObj,
                    setLikeObj,
                    parentCommentId,
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleMatchDetails;
