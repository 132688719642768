import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20"
      onClick={onClose}
    >
      <div
        className="rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full bg-[#00251C]">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
