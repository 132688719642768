import Assets from "src/assets";
import Button from "./Button";

const PerformanceCard = ({
  title,
  rate,
  isTeam = false,
}: {
  title: string;
  rate: number;
  isTeam?: boolean | undefined;
}) => {
  return (
    <div className="col-span-1 h-[68px] bg-[#ECF9F0]/20 flex justify-between items-center py-3 px-8">
      <h5 className="text-white text-sm">{title}</h5>
      {isTeam ? (
        <div className="flex">
          <p className="self-center text-lg text-white">Arsenal</p>
          <img src={Assets.team} className="size-[51px]" alt="Team" />

          <Button className="!bg-transparent !size-6">
            {" "}
            <img src={Assets.editIcon} className="size-6" alt="Team" />{" "}
          </Button>
        </div>
      ) : (
        <h5 className="text-[#1BC16B] text-4xl">{rate}</h5>
      )}
    </div>
  );
};

export default PerformanceCard;
