import logo from "./logo.png";
import grass from "./sport-grass.jpeg";
import grass2 from "./sport-grass2.png";
import dashboardGrass from "./dashboard-grass.png";
import rough from "./rough.png";
import winner from "./winner.png";
import whiteJersey from "./white-jersey.png";
import blueJersey from "./blue-jersey.png";
import dummyAvatar from "./dummy-avatar.webp";
import stadium from "./stadium.svg";
import field from "./field.svg";
import avatar from "./avatar.png"
import badge from "./badge.svg"
import share from "./share.svg"
import team from "./Team.png"
import editIcon from "./edit-icon.svg"

const Assets = {
  logo,
  grass,
  grass2,
  dashboardGrass,
  rough,
  winner,
  whiteJersey,
  blueJersey,
  dummyAvatar,
  stadium,
  field,
  avatar,
  badge,
  share,
  team,
  editIcon,
};

export default Assets;
