import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../App";
import Home from "../pages/home/views/Home";
import Landing from "../layouts/Landing";
import AuthLayout from "src/layouts/AuthLayout";
import SignIn from "src/pages/auth/views/SignIn";
import SignUp from "src/pages/auth/views/SignUp";
import ForgotPassword from "src/pages/auth/views/ForgotPassword";
import EmailVerified from "src/pages/auth/views/EmailVerified";
import SetNewPassword from "src/pages/auth/views/SetNewPassword";
import PasswordReset from "src/pages/auth/views/PasswordReset";
import VerifyOTP from "src/pages/auth/views/VerifyOTP";
import DashboardLayout from "src/layouts/DashboardLayout";
import Profile from "src/pages/dashboard/views/Profile";
import VerifyEmailOTP from "src/pages/auth/views/VerifyEmailOTP";
import Leaderboard from "src/pages/dashboard/views/Leaderboard";
import UpcomingMatches from "src/pages/dashboard/views/UpcomingMatches";
import TermsOFUse from "src/pages/home/views/TermsOFUse";
import SingleMatchDetails from "src/pages/dashboard/views/SingleMatchDetails";

export const pageRoutes = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
        ],
      },

      {
        path: "/terms-of-use",
        element: <TermsOFUse />,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: "/sign-in",
            element: <SignIn />,
          },
          {
            path: "/forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "/new-password",
            element: <SetNewPassword />,
          },
          {
            path: "/reset-password",
            element: <PasswordReset />,
          },
          {
            path: "/sign-up",
            element: <SignUp />,
          },
          {
            path: "/verify-otp/:email",
            element: <VerifyOTP />,
          },
          {
            path: "/verify-email/:email",
            element: <VerifyEmailOTP />,
          },
          {
            path: "/success",
            element: <EmailVerified />,
          },
        ],
      },

      {
        element: <DashboardLayout />,
        children: [
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/leaderboard",
            element: <Leaderboard />,
          },
          {
            path: "/upcoming-matches",
            element: <UpcomingMatches />,
          },
          {
            path: "/upcoming-matches/:id",
            element: <SingleMatchDetails />,
          },
        ],
      },
    ],
  },
]);
