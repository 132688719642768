import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// import { RootState } from "../store";

interface User {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  profile_picture: string | null;
  nationality: string | null;
  telegram_user_name: string | null;
  x_profile_link: string | null;
  wallet: string | null;
  email: string;
}

interface Fixture {
  id: number;
  referee: string | null;
  timezone: string;
  date: string;
  timestamp: number;
  periods: {
    first: number;
    second: number | null;
  };
  venue: {
    id: number;
    name: string;
    city: string;
  };
  status: {
    long: string;
    short: string;
    elapsed: number;
  };
}

interface League {
  id: number;
  name: string;
  country: string;
  logo: string;
  flag: string;
  season: number;
  round: string;
}

interface Team {
  id: number;
  name: string;
  logo: string;
}

interface Teams {
  home: Team;
  away: Team;
}

export interface MatchData {
  details: {
    fixture: Fixture;
    league: League;
    teams: Teams;
    goals?: {
      home: string;
      away: string;
    };
    last_games?: { home?: string[]; away?: string[] };
  };
  id: string;
  predicted: boolean;
  predictions: { prediction: string }[];
}

// Define a type for the slice state
export interface LeaderboardProp {
  totalPoints: number;
  user: User;
  stats: {
    average_points_per_prediction: number;
    previous_round_score: number;
    progressIndicator: null | "UP" | "DOWN" | "EQ";
  };
}

interface IAppState {
  leaderboard: LeaderboardProp[];
  homeMatches: MatchData[][];
  matches: MatchData[][];
  total_rounds: number;
  current_round: number;
  loading_state: boolean;
  user_blocked: boolean;
}

// Define the initial state using that type
const initialState: IAppState = {
  leaderboard: [],
  homeMatches: [],
  matches: [],
  current_round: 0,
  total_rounds: 0,
  loading_state: true,
  user_blocked: false,
};

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    bulkAddLeaderboard: (state, action: PayloadAction<LeaderboardProp[]>) => {
      state.leaderboard = action.payload;
    },

    bulkSetHomeMatches: (state, action: PayloadAction<MatchData[][]>) => {
      state.homeMatches = action.payload;
    },

    bulkSetMatches: (state, action: PayloadAction<MatchData[][]>) => {
      state.matches = action.payload;
    },

    setRounds: (state, action: PayloadAction<number[]>) => {
      state.total_rounds = action.payload[0];
      state.current_round = action.payload[1];
    },

    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading_state = action.payload;
    },

    clearState: (state) => {
      state = {
        leaderboard: [],
        homeMatches: [],
        matches: [],
        current_round: 0,
        total_rounds: 0,
        loading_state: true,
        user_blocked: false,
      };
    },

    setBlocked: (state, action: PayloadAction<boolean>) => {
      state.user_blocked = action.payload;
    },

    updatePredictionState: (
      state,
      action: PayloadAction<{
        matchId: string;
        keyIndex: number;
        prediction: string;
      }>
    ) => {
      const matchId = action.payload.matchId;
      const keyIndex = action.payload.keyIndex;
      const prediction = action.payload.prediction;
      if (keyIndex < state.matches.length && state.matches[keyIndex].length) {
        const newMatchesConstruct = state.matches[keyIndex].map((mp) => {
          if (mp.details.fixture.id.toString() === matchId) {
            return { ...mp, predicted: true, predictions: [{ prediction }] };
          }
          return mp;
        });
        state.matches[keyIndex] = newMatchesConstruct;
      }
    },
  },
});

export const {
  bulkAddLeaderboard,
  bulkSetHomeMatches,
  bulkSetMatches,
  setRounds,
  updatePredictionState,
  setLoadingState,
  setBlocked,
  clearState,
} = appSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default appSlice.reducer;
