import { Head2Head, WinProbability } from "src/types";
import StatisticCard, { Stat } from "./StatisticCard";
import dayjs from "dayjs";
import { useState } from "react";
import Button from "./Button";
import { cn } from "src/lib/utils";

const MatchStatistics = ({
  stats,
  head2head,
  isLoading,
  predictions,
}: {
  stats: Stat[];
  head2head: Head2Head[];
  isLoading: boolean;
  predictions: WinProbability;
}) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const displayedHead2Head = showAll ? head2head : head2head?.slice(0, 5);

  const data = {
    home: Number(predictions.percent?.home?.replace("%", "")),
    draw: Number(predictions.percent?.draw?.replace("%", "")),
    away: Number(predictions.percent?.away?.replace("%", "")),
  };

  const adjustedValues = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, Math.sqrt(value)])
  );

  // Normalize to 100%
  const totalAdjusted = Object.values(adjustedValues).reduce(
    (sum, val) => sum + val,
    0
  );
  const normalizedValues = Object.fromEntries(
    Object.entries(adjustedValues).map(([key, value]) => [
      key,
      (value / totalAdjusted) * 100,
    ])
  );

  return (
    <div className="flex flex-col justify-center pb-4 lg:px-4 px-2">
      {predictions && (
        <div className="space-y-6 mb-16 lg:w-3/4 w-full mx-auto">
          <div className="flex items-center justify-center">
            <h6 className="text-center mb-1 capitalize">Win Probability</h6>
          </div>
          <div className="flex h-8 gap-x-2">
            {data.home !== 0 && (
              <div
                className={cn(
                  "h-full rounded-[7px] transition-all flex items-center justify-start pl-2 bg-[#034694] "
                )}
                style={{
                  width: `${normalizedValues.home + "%"}`,
                }}
              >
                {" "}
                <div className="w-full text-[12px] lg:text-sm ">
                  {predictions.percent?.home} Home
                </div>
              </div>
            )}

            {data.draw !== 0 && (
              <div
                className={cn(
                  "h-full rounded-[7px] transition-all flex items-center justify-end  ",
                  "bg-[#DADADA]"
                )}
                style={{
                  width: `${normalizedValues.draw + "%"}`,
                }}
              >
                <div className="text-right text-black text-[12px] lg:text-sm pr-2 ">
                  {predictions.percent?.draw} Draw
                </div>
              </div>
            )}
            {data.away !== 0 && (
              <div
                className={cn(
                  "h-full rounded-[7px] transition-all flex items-center lg:justify-end justify-center ",
                  "bg-white"
                )}
                style={{
                  width: `${normalizedValues.away + "%"}`,
                }}
              >
                <div className="text-right text-black lg:pr-2 text-[12px] lg:text-sm ">
                  {predictions.percent?.away} Away
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {stats?.length ? (
        <StatisticCard stats={stats} />
      ) : (
        <div className="w-full h-6 flex items-center justify-center">
          Match stats will be available when Match starts
        </div>
      )}

      <hr className="my-10 bg-black" />
      {isLoading ? (
        <p>Loading</p>
      ) : displayedHead2Head?.length ? (
        <div className="lg:w-3/4 w-full mx-auto">
          <h1 className="text-center text-2xl pb-4">Head To Head</h1>
          {displayedHead2Head?.map((head2head, index) => (
            <Head2HeadCard key={index} head2head={head2head} />
          ))}

          {head2head?.length >= 5 ? (
            <Button className="mt-4" onClick={handleShowMore}>
              {showAll ? "Show Less" : "Show More"}
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const Head2HeadCard = ({ head2head }: { head2head: Head2Head }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full bg-[#00251C]/50">
      <h5 className="bg-[#00251C] p-2 w-full text-center uppercase lg:text-base text-[12px]">
        {dayjs(head2head?.fixture?.date).format("dddd DD MMMM YYYY")}
      </h5>
      <div className="flex justify-between items-center w-full p-2">
        <div className="flex items-center gap-x-4 w-11/12">
          <img
            src={head2head?.teams?.home?.logo}
            alt={head2head?.teams?.home?.name}
            className="w-8 h-8"
          />
          <p className="text-sm lg:text-base">{head2head?.teams?.home?.name}</p>
        </div>
        <div className="w-4/12 flex items-center justify-center">
          <p>
            {head2head?.goals?.home} - {head2head?.goals?.away}
          </p>
        </div>
        <div className="flex items-center justify-end gap-x-4 w-11/12">
          <p className="text-sm lg:text-base">{head2head?.teams?.away?.name}</p>
          <img
            src={head2head?.teams?.away?.logo}
            alt={head2head?.teams?.away?.name}
            className="w-8 h-8"
          />
        </div>
      </div>
    </div>
  );
};
export default MatchStatistics;
