import Assets from "src/assets";
import Button from "./Button";
import { TbLoader } from "react-icons/tb";

const CreateComment = ({
  setComment,
  handleSend,
  comment,
  isLoading,
  name,
  rank,
  profile_picture,
}: {
  setComment: React.Dispatch<React.SetStateAction<string>>;
  handleSend: () => void;
  isLoading?: boolean;
  comment: string;
  name: string;
  rank: number | string;
  profile_picture: string;
}) => {
  return (
    <div className="lg:px-8 px-4 py-[22px] border-2 border-border">
      <div className="h-12 w-full bg-[#ECF9F0]/20 px-7 flex items-center justify-between">
        <div className="h-8 w-8 rounded-full bg-secondary flex items-center gap-x-4">
          {profile_picture ? (
            <img
              src={profile_picture}
              alt="avatar"
              className="h-8 w-8 rounded-full"
            />
          ) : (
            <img
              src={Assets.dummyAvatar}
              alt="avatar"
              className="h-8 w-8 rounded-full"
            />
          )}
          <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">{name}</h6>
        </div>

        <div className="flex items-center gap-x-4">
          <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">Ranking</h6>
          <h6 className="text-[#1BC16B] lg:text-[15px] text-[12px]">{rank}</h6>
        </div>
      </div>
      <div className=" border-y-2 border-y-border w-full mt-1 pt-4 pb-2">
        <textarea
          required
          className="bg-transparent w-full outline-none"
          placeholder="Type your comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </div>

      <div className="flex items-center gap-x-2.5 mt-4">
        <Button
          disabled={isLoading || !comment}
          onClick={handleSend}
          className="!bg-[#003428] !h-[29px] border-none !w-[92px] !text-[10px] !text-[#ECF9F0] ml-[6px] rounded-[1.8px]"
        >
          {isLoading ? (
            <TbLoader size={22} className="mr-1.5 animate-spin" />
          ) : (
            "Post Comment"
          )}
        </Button>
      </div>
    </div>
  );
};

export default CreateComment;
