import { Outlet } from "react-router-dom";
import { apiClient, groupMatchesByDate } from "./lib/utils";
import { useEffect } from "react";
import { useAppDispatch } from "./state/store";
import {
  bulkAddLeaderboard,
  bulkSetHomeMatches,
  bulkSetMatches,
  setBlocked,
  setLoadingState,
  setRounds,
} from "./state/slices/appSlice";
import toast from "react-hot-toast";

export default function AppLayout() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async function () {
      const credentials = localStorage.getItem("credentials");
      try {
        const [
          home_match_response,
          leaderboard_response,
          match_response,
          user_response,
        ] = await Promise.all([
          apiClient.get("/match/home", {
            params: {
              // page: 0,
              // size: 20,
            },
          }),

          apiClient.get("/score", {
            params: {
              // page: 0,
              // size: 20,
            },
          }),
          credentials
            ? apiClient.get("/match", {
                params: {
                  // page: 0,
                  // size: 100,
                },
                headers: {
                  Authorization: `bearer ${
                    JSON.parse(credentials)?.accessToken
                  }`,
                },
              })
            : null,

          credentials
            ? apiClient.get(`/users/${JSON.parse(credentials)?.id}`, {
                headers: {
                  Authorization: `bearer ${
                    JSON.parse(credentials)?.accessToken
                  }`,
                },
              })
            : null,
        ]);

        if (match_response?.data?.success) {
          const groupedMatches = groupMatchesByDate(
            match_response.data.data?.matches?.rows
          );
          dispatch(
            setRounds([
              match_response.data.data.total_rounds,
              match_response.data.data.current_round,
            ])
          );
          dispatch(bulkSetMatches(groupedMatches));
        }

        if (leaderboard_response.data.success) {
          dispatch(
            bulkAddLeaderboard(
              Array.isArray(leaderboard_response.data.data.results)
                ? leaderboard_response.data.data.results
                : []
            )
          );

          dispatch(
            setRounds([
              leaderboard_response.data.data.rounds.total_rounds,
              leaderboard_response.data.data.rounds.current_round,
            ])
          );
        }

        if (home_match_response.data.success) {
          const groupedMatches = groupMatchesByDate(
            home_match_response?.data?.data?.rows
          );

          dispatch(bulkSetHomeMatches(groupedMatches));
        }

        if (user_response?.data?.success) {
          if (user_response?.data?.data.status !== "active") {
            dispatch(setBlocked(true));
            // dispatch(clearState());
            localStorage.clear();
          } else {
            localStorage.setItem(
              "credentials",
              JSON.stringify({
                ...JSON.parse(credentials!),
                ...user_response.data.data,
              })
            );
          }
        }

        dispatch(setLoadingState(false));
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || "OOPPSS!! Something went wrong"
        );
      }
    })();
  }, []);
  return <Outlet />;
}
