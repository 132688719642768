import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "src/lib/utils";
import { IAuthResponse, ILoginData, IRegisterData } from "src/types";

export const handleSignIn = createAsyncThunk<
  IAuthResponse,
  ILoginData,
  { rejectValue: string }
>("app/signIn", async (result: ILoginData, thunkAPI) => {
  try {
    const response = await apiClient.post("/auth/sign-in", result);
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const handleSignUp = createAsyncThunk<
  IAuthResponse,
  IRegisterData,
  { rejectValue: string }
>("app/signUp", async (result: IRegisterData, thunkAPI) => {
  try {
    const response = await apiClient.post("/auth/sign-up", result);
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const verifyOtp = createAsyncThunk<
  IAuthResponse,
  { token: string },
  { rejectValue: string }
>("app/verifyOtp", async ({ token }, thunkAPI) => {
  try {
    const response = await apiClient.get(`/auth/verify/${token}`);
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const setNewPassword = createAsyncThunk<
  IAuthResponse,
  { newPassword: string },
  { rejectValue: string }
>("app/verifyOtp", async ({ newPassword }, thunkAPI) => {
  try {
    const response = await apiClient.post(`/auth/reset-password`, {
      newPassword: newPassword,
    });
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const handleResendVerification = createAsyncThunk<
  IAuthResponse,
  string,
  { rejectValue: string }
>("app/resendVerification", async (email: string, thunkAPI) => {
  try {
    const response = await apiClient.post("/auth/send-verification", {
      email: email,
    });
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    return thunkAPI.rejectWithValue(errorMessage);
  }
});
