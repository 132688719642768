import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Assets from "src/assets";
import MaxWrapper from "./MaxWrapper";
import { cn } from "src/lib/utils";
import { useLocalStorage } from "../controllers/localstorage";
import { useAppDispatch } from "src/state/store";
import { clearAuth } from "src/state/slices/authSlice";
import ScrollToTop from "./ScrollToTop";

export default function Header() {
  const { logo } = Assets;
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { getFn } = useLocalStorage();
  const dispatch = useAppDispatch();
  const [isHome, setIsHome] = useState<boolean>(false);
  const credentials = getFn("credentials");

  // Function to handle scroll events
  const handleScroll = () => {
    if (window.scrollY > 10) {
      // Adjust this value to your desired scroll position
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // Add and remove scroll event listeners
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHome(true);
    }
  }, [location]);

  return (
    <header
      className={cn(
        "flex h-14 w-full sticky top-0 left-0 z-40 transition-all duration-300 px-4 md:px-0",
        {
          "backdrop-blur-md bg-background/20 h-16 md:h-20": isScrolled,
        }
      )}
    >
      <ScrollToTop />
      <MaxWrapper className="size-full flex items-center justify-between">
        <div className="flex items-center gap-8">
          <Link to="/" className="size-fit">
            <img
              src={logo}
              alt="logo"
              height={24}
              width={168}
              className="object-contain"
            />
          </Link>

          {/* <div className="hidden md:flex items-center gap-8">
            <h4 className="uppercase text-base relative w-max group">
              <Link to="/">Product 1</Link>
              <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
            </h4>
            <h4 className="uppercase text-base relative w-max group">
              <Link to="/">Product 2</Link>
              <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
            </h4>
            <h4 className="uppercase text-base relative w-max group">
              <Link to="/">Product 3</Link>
              <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
            </h4>
          </div> */}
        </div>

        <div className="flex items-center gap-8">
          {credentials !== undefined ? (
            isHome ? null : (
              // <button
              //   onClick={() => {
              //     navigate("/upcoming-matches");
              //   }}
              //   className="uppercase text-base relative w-max group cursor-pointer"
              // >
              //   Start Predicting
              //   <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
              // </button>
              <button
                onClick={() => {
                  localStorage.clear();
                  dispatch(clearAuth());
                  navigate("/");
                }}
                className="uppercase text-base relative w-max group cursor-pointer"
              >
                Log Out
                <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
              </button>
            )
          ) : (
            <>
              <h4 className="uppercase text-base relative w-max group">
                <Link to="/sign-in">Login</Link>
                <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
              </h4>
              <h4 className="uppercase text-base relative w-max group">
                <Link to="/sign-up">Sign up</Link>
                <span className="absolute -bottom-px left-0 h-px w-0 group-hover:w-full transition-all duration-200 bg-white" />
              </h4>
            </>
          )}
        </div>
      </MaxWrapper>
    </header>
  );
}
