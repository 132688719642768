import { Badge } from "src/types";

const AchievementCard = ({
  badges,
  handleView,
}: {
  badges: Badge[];
  handleView: any;
}) => {
  return (
    <div className="space-y-3">
      <div className="w-full bg-[#ECF9F0]/20 py-3 px-8 flex flex-col justify-between">
        <h5 className="text-sm"> Achievements</h5>

        <div className="flex gap-4 mt-2">
          {badges?.map((badge, index) => (
            <div
              onClick={() => handleView(badge)}
              key={index}
              className="flex flex-col justify-center items-center gap-1 relative cursor-pointer"
            >
              <img
                src={badge.iconUrl}
                alt="Badge"
                className="size-[80px] object-contain"
              />

              {/* {show.show && show.badgeName === badge.badgeName && ( */}
              <div className="  bg-[#00251C]/20 py-1 px-2">
                <p className="text-[10px]">{badge.badgeName}</p>
              </div>
              <p className="text-sm font-semibold">
                {/* {badge.badgeName} */}
                <span>{badge.noOfTimesAwarded}x</span>
              </p>
              {/* )} */}
            </div>
          ))}
        </div>
      </div>

      {/* <div className="bg-white h-[56px] flex justify-center items-center gap-x-2.5 py-3 px-8">
        <h5 className="text-[#00251C] text-lg">Share Achievements</h5>
        <img src={Assets.share} alt="Badge" />
      </div> */}
    </div>
  );
};

export default AchievementCard;
