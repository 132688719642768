import { cn } from "src/lib/utils";
import Button from "./Button";
import { useState } from "react";

export interface Stat {
  type: string;
  home: number | string;
  away: number | string;
  homePercentage?: number;
  awayPercentage?: number;
}

const StatisticCard = ({ stats }: { stats: Stat[] }) => {
  function calculatePercentage(left: number, right: number): [number, number] {
    const total = left + right;
    if (total === 0) {
      return [0, 0];
    }
    const homePercentage = (left / total) * 100;
    const awayPercentage = (right / total) * 100;
    return [Math.floor(homePercentage), Math.floor(awayPercentage)];
  }

  const updatedStats = stats?.map((newItems: Stat) => {
    const [homePercentage, awayPercentage] = calculatePercentage(
      Number(newItems.home?.toString()?.replace("%", "")),
      Number(newItems.away?.toString().replace("%", ""))
    );
    return {
      ...newItems,
      homePercentage,
      awayPercentage,
    };
  });

  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const displayedUpdatedStats = showAll
    ? updatedStats
    : updatedStats?.slice(0, 8);
  return (
    <div className="space-y-4 lg:w-3/4 w-full mx-auto">
      {displayedUpdatedStats
        ?.filter((item) => item.away !== null && item.home !== null)
        ?.map((stat: Stat) => (
          <div key={stat?.type}>
            <div className="flex items-center justify-center">
              <h6 className="text-center mb-1 capitalize">
                {stat?.type?.replaceAll("_", " ")}
              </h6>
            </div>
            <div className="relative w-full flex items-center gap-4 h-8 rounded-[7px]">
              <div className="h-8 w-[560px] bg-gray-500/20 overflow-hidden rounded-[7px]">
                <div
                  className={cn(
                    "h-full px-6 flex items-center justify-center",
                    stat?.home !== 0
                      ? "bg-[#034694]"
                      : "bg-transparent !text-white"
                  )}
                  style={{
                    width: `${
                      stat?.home.toString().includes("%")
                        ? stat.home
                        : stat.homePercentage + "%"
                    }`,
                    maxWidth: "100%",
                  }}
                >
                  {" "}
                  <div className="text-left pr-2">{stat?.home}</div>
                </div>
              </div>
              <div className="h-8 w-[560px] bg-gray-500/20 overflow-hidden rounded-[7px] flex items-center justify-end">
                <div
                  className={cn(
                    "h-full px-6 flex items-center justify-center",
                    stat?.away !== 0
                      ? "bg-white  text-black"
                      : "bg-transparent !text-white"
                  )}
                  style={{
                    width: `${
                      stat?.away.toString().includes("%")
                        ? stat.away
                        : stat.awayPercentage + "%"
                    }`,
                    maxWidth: "100%",
                  }}
                >
                  <div className="text-right pr-2">{stat?.away}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {updatedStats?.length >= 5 ? (
        <Button className="mt-4" onClick={handleShowMore}>
          {showAll ? "Show Less" : "Show More"}
        </Button>
      ) : null}
    </div>
  );
};

export default StatisticCard;
{
  /* <div className=" relative flex gap-x-5 items-center h-8 bg-foreground/10 w-full">
  <div
    className={cn(
      "h-full absolute left-0 rounded-[7px] transition-all flex items-center justify-start pl-2 bg-[#034694] w-1/2"
    )}
    style={{ width: `${stat?.homePercentage}%` }}
  >
    {" "}
    <div className="text-left pr-2">{stat?.home}</div>
  </div>
  <div
    className={cn(
      "h-full absolute right-0 rounded-[7px] transition-all flex items-center justify-end pr-2",
      "bg-white w-1/2"
    )}
    style={{ width: `${stat?.awayPercentage}%` }}
  >
    <div className="text-right text-black pr-2">{stat?.away}</div>
  </div>
</div>; */
}
