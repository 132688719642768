import React from "react";
import Assets from "src/assets";
import { cn } from "src/lib/utils";
import { CommentProps } from "src/types";
import Button from "./Button";
import CreateComment from "./CreateComment";
import { Icons } from "./Icons";

const Comment = ({
  item,
  handleSend,
  setComment,
  setParentCommentId,
  comment,
  isLoading,
  toggle,
  setToggle,
  postReactionMutation,
  likeObj,
  setLikeObj,
  parentCommentId,
  name,
  rank,
  profile_picture,
}: {
  item: CommentProps;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  handleSend: () => void;
  isLoading: boolean;
  comment: string;
  setParentCommentId: any;
  toggle: boolean;
  setToggle: any;
  postReactionMutation: any;
  likeObj: any;
  setLikeObj: any;
  parentCommentId: any;
  name: string;
  rank: number | string;
  profile_picture: string;
}) => {
  return (
    <div
      className="w-full bg-[#00251C]  py-3 lg:px-9 px-4 flex flex-col"
      style={{
        boxShadow: "0px 4px 8px 0px rgba(10, 58, 100, 0.15)",
      }}
    >
      <div>
        <div className="h-12 w-full bg-[#ECF9F0]/20 px-7 flex items-center justify-between">
          <div className="h-8 w-8 rounded-full bg-secondary flex items-center gap-x-4">
            {item.user?.profile_picture ? (
              <img
                src={item.user?.profile_picture}
                alt="avatar"
                className="h-8 w-8 rounded-full"
              />
            ) : (
              <img
                src={Assets.dummyAvatar}
                alt="avatar"
                className="h-8 w-8 rounded-full"
              />
            )}
            <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">
              {item.user?.username}
            </h6>
          </div>

          <div className="flex items-center gap-x-4">
            <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">
              Ranking
            </h6>
            <h6 className="text-[#1BC16B] lg:text-[15px] text-[12px]">
              {item.user?.rank}
            </h6>
          </div>
        </div>
        <div className=" border-y-2 border-y-border w-full mt-1 pt-4 pb-2 lg:text-base text-sm">
          <p>{item.content}</p>
        </div>
      </div>

      <div className="flex items-center gap-x-2.5 mt-4">
        <Button
          onClick={() => {
            setLikeObj({
              id: item.id,
              type: "liked",
            });
            const payload = {
              commentId: item.id,
              type: "liked",
            };
            postReactionMutation.mutate({
              route: "/match/comments/like-or-dislike",
              payload,
            });
          }}
          className="!bg-[#2F4F46] !h-6 !w-6 text-red-600"
        >
          <Icons.LikeIcon
            className={cn(
              (likeObj.id === item.id && likeObj.type === "liked") ||
                item.userLikeStatus?.type === "liked"
                ? "text-[#28A267]"
                : "text-white"
            )}
          />
        </Button>
        <Button
          onClick={() => {
            setLikeObj({
              id: item.id,
              type: "disliked",
            });
            const payload = {
              commentId: item.id,
              type: "disliked",
            };
            postReactionMutation.mutate({
              route: "/match/comments/like-or-dislike",
              payload,
            });
          }}
          className="!bg-[#2F4F46] !h-6 !w-6"
        >
          <Icons.DislikeIcon
            className={cn(
              (likeObj.id === item.id && likeObj.type === "disliked") ||
                item.userLikeStatus?.type === "disliked"
                ? "text-[#28A267]"
                : "text-white"
            )}
          />
        </Button>

        <Button
          className="!bg-[#003428] !h-[29px] !w-[68px] border-none !text-[10px] !text-[#ECF9F0] ml-[6px]"
          onClick={() => {
            setParentCommentId(item.id);
            setToggle(!toggle);
          }}
        >
          Comment
        </Button>
      </div>

      <div className="ml-5 mt-5 space-y-5 w-full">
        {toggle && parentCommentId === item?.id && (
          <CreateComment
            {...{
              name,
              rank,
              comment,
              setComment,
              handleSend,
              isLoading,
              profile_picture,
            }}
          />
        )}
        {item?.replies?.map((reply: CommentProps) => (
          <div
            key={reply.id}
            className="border w-full p-4"
            style={{
              boxShadow: "0px 4px 8px 0px rgba(10, 58, 100, 0.15)",
            }}
          >
            <div className="h-12 w-full bg-[#ECF9F0]/20 px-7 flex items-center justify-between">
              <div className="h-8 w-8 rounded-full bg-secondary flex items-center gap-x-4">
                {reply.user?.profile_picture ? (
                  <img
                    src={reply.user?.profile_picture}
                    alt="avatar"
                    className="h-8 w-8 rounded-full"
                  />
                ) : (
                  <img
                    src={Assets.dummyAvatar}
                    alt="avatar"
                    className="h-8 w-8 rounded-full"
                  />
                )}
                <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">
                  {reply.user?.username}
                </h6>
              </div>

              <div className="flex items-center gap-x-4">
                <h6 className="text-[#ECF9F0] lg:text-[15px] text-[12px]">
                  Ranking
                </h6>
                <h6 className="text-[#1BC16B] lg:text-[15px] text-[12px]">
                  {reply.user?.rank}
                </h6>
              </div>
            </div>
            <div className=" border-t-2 border-t-border w-full mt-1 pt-4 pb-2 lg:text-base text-sm">
              <p>{reply.content}</p>
            </div>
            <div className="flex items-center gap-x-2.5 mt-4">
              <Button
                onClick={() => {
                  setLikeObj({
                    id: reply.id,
                    type: "liked",
                  });
                  const payload = {
                    commentId: reply.id,
                    type: "liked",
                  };
                  postReactionMutation.mutate({
                    route: "/match/comments/like-or-dislike",
                    payload,
                  });
                }}
                className="!bg-[#2F4F46] !h-6 !w-6 text-red-600"
              >
                <Icons.LikeIcon
                  className={cn(
                    (likeObj.id === reply.id && likeObj.type === "liked") ||
                      reply.userLikeStatus?.type === "liked"
                      ? "text-[#28A267]"
                      : "text-white"
                  )}
                />
              </Button>
              <Button
                onClick={() => {
                  setLikeObj({
                    id: reply.id,
                    type: "disliked",
                  });
                  const payload = {
                    commentId: reply.id,
                    type: "disliked",
                  };
                  postReactionMutation.mutate({
                    route: "/match/comments/like-or-dislike",
                    payload,
                  });
                }}
                className="!bg-[#2F4F46] !h-6 !w-6"
              >
                <Icons.DislikeIcon
                  className={cn(
                    (likeObj.id === reply.id && likeObj.type === "disliked") ||
                      reply.userLikeStatus?.type === "disliked"
                      ? "text-[#28A267]"
                      : "text-white"
                  )}
                />
              </Button>

              {/* <Button
                className="!bg-[#003428] !h-[29px] !w-[68px] border-none !text-[10px] !text-[#ECF9F0] ml-[6px]"
                onClick={() => {
                  setParentCommentId(item.id);
                  setToggle(!toggle);
                }}
              >
                Comment
              </Button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comment;
