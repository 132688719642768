import React from "react";
import Assets from "src/assets";
import { InjuriesType } from "src/injury.type";
import { cn } from "src/lib/utils";
import { Club, Goalkeeper, Player, StartXi } from "src/types";

interface TeamLineUpProps {
  team: Club;
  injuries: InjuriesType[];
}

const TeamLineUp: React.FC<TeamLineUpProps> = ({ team, injuries }) => {
  const groupPlayersByPosition = (players: StartXi[]) => {
    const positions = {
      G: [] as StartXi[], // Goalkeeper
      D: [] as StartXi[], // Defenders
      M: [] as StartXi[], // Midfielders
      F: [] as StartXi[], // Forwards
    };

    players?.forEach((player) => {
      if (player.player.pos && positions.hasOwnProperty(player.player.pos)) {
        positions[player.player.pos].push(player);
      }
    });

    return positions;
  };

  const positionedPlayers = groupPlayersByPosition(team?.startXI ?? []);
  const injury = injuries?.filter((item) => item.team?.id === team.team.id);

  return (
    <div className="flex flex-col gapy-y-3 pb-2 lg:w-[616px] w-full">
      <h4>Head Coach</h4>
      <div className="bg-[#00251C] h-[56px] border-2 border-y-borderColor flex items-center px-8 mt-3">
        <p className="text-2xl font-semibold">{team?.coach?.name}</p>
      </div>{" "}
      {team?.formation ? (
        <div className="h-11 bg-[#1F9059] flex justify-between items-center px-4 mt-8">
          <p className="text-sm font-medium">Formation:</p>
          <p className="text-sm font-medium">{team.formation}</p>
        </div>
      ) : null}
      {positionedPlayers.D.length &&
      positionedPlayers.F.length &&
      positionedPlayers.M.length &&
      positionedPlayers.G.length ? (
        <div className=" w-full relative object-contain lg:h-[608px] h-[400px]">
          <img src={Assets.field} alt="field" className="absolute" />

          {/* Display players by position */}
          <div className="absolute inset-0 flex flex-col justify-between pt-4  pb-20 h-full">
            {/* Goalkeeper */}
            {positionedPlayers.G.length && (
              <div className="flex justify-center">
                {positionedPlayers.G?.map((player) => (
                  <PlayerIcon
                    key={player.player.id}
                    player={player.player}
                    teamColor={{
                      keeper: {
                        primary: team.team?.colors?.goalkeeper?.primary,
                        border: team.team?.colors?.goalkeeper?.border,
                        number: team.team?.colors?.goalkeeper?.number,
                      },
                      player: {
                        primary: team.team?.colors?.player?.primary,
                        border: team.team?.colors?.player?.border,
                        number: team.team?.colors?.player?.number,
                      },
                    }}
                  />
                ))}
              </div>
            )}

            {/* Defenders */}
            {positionedPlayers.D.length && (
              <div className="flex justify-around">
                {positionedPlayers.D?.map((player) => (
                  <PlayerIcon
                    key={player.player.id}
                    player={player.player}
                    teamColor={{
                      keeper: {
                        primary: team.team?.colors?.goalkeeper?.primary,
                        border: team.team?.colors?.goalkeeper?.border,
                        number: team.team?.colors?.goalkeeper?.number,
                      },
                      player: {
                        primary: team.team?.colors?.player?.primary,
                        border: team.team?.colors?.player?.border,
                        number: team.team?.colors?.player?.number,
                      },
                    }}
                  />
                ))}
              </div>
            )}

            {/* Midfielders */}
            {positionedPlayers.M.length && (
              <div className="flex justify-around">
                {positionedPlayers.M?.map((player) => (
                  <PlayerIcon
                    key={player.player.id}
                    player={player.player}
                    teamColor={{
                      keeper: {
                        primary: team.team?.colors?.goalkeeper?.primary,
                        border: team.team?.colors?.goalkeeper?.border,
                        number: team.team?.colors?.goalkeeper?.number,
                      },
                      player: {
                        primary: team.team?.colors?.player?.primary,
                        border: team.team?.colors?.player?.border,
                        number: team.team?.colors?.player?.number,
                      },
                    }}
                  />
                ))}
              </div>
            )}
            {/* Forwards */}
            {positionedPlayers.F.length && (
              <div className="flex justify-around">
                {positionedPlayers.F?.map((player) => (
                  <PlayerIcon
                    key={player.player.id}
                    player={player.player}
                    teamColor={{
                      keeper: {
                        primary: team.team?.colors?.goalkeeper?.primary,
                        border: team.team?.colors?.goalkeeper?.border,
                        number: team.team?.colors?.goalkeeper?.number,
                      },
                      player: {
                        primary: team.team?.colors?.player?.primary,
                        border: team.team?.colors?.player?.border,
                        number: team.team?.colors?.player?.number,
                      },
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : null}
      {team?.substitutes?.length ? (
        <div>
          <h4 className="my-5 text-xl font-normal">Reserve</h4>
          <div className="lg:h-[340px] lg:overflow-auto  w-full">
            {team?.substitutes?.map((item) => (
              <div
                key={item.player.id}
                className="h-12 w-full bg-primary px-8 py-3 border-t-[2px] border-t-border"
              >
                <p className="text-white text-sm font-medium flex items-center gap-x-3">
                  {item.player.number && <span> {item.player.number}.</span>}
                  <span> {item.player.name}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {injury?.length ? (
        <>
          <h4 className="my-5 text-xl font-normal">Injury/Suspension</h4>
          <div className="lg:h-[340px] lg:overflow-auto  w-full">
            {injury?.map((item: InjuriesType) => (
              <div
                key={item.player?.id}
                className="h-12 w-full bg-primary px-2 py-3 border-t-[2px] border-t-border flex items-center gap-x-4"
              >
                <img
                  src={item.player?.photo}
                  alt={item.player?.name}
                  className="w-8 h-8 rounded-full"
                />
                <p className="text-white text-sm font-medium flex items-center gap-x-3">
                  <span> {item.player?.name}.</span>
                  <span>({item.player?.reason})</span>
                </p>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

const PlayerIcon = ({
  player,
  teamColor,
}: {
  player: Player;
  teamColor: { keeper: Goalkeeper; player: Goalkeeper };
}) => (
  <div className="flex flex-col items-center">
    <div
      className={cn(
        "w-8 h-8 rounded-full flex items-center justify-center text-sm"
      )}
      style={
        player.pos === "G"
          ? {
              background: `#${teamColor.keeper.primary ?? "2d397a"}`,
              border: `1px solid #${teamColor.keeper.border ?? "eee"}`,
              color: `#${teamColor.keeper.number}`,
            }
          : {
              background: `#${teamColor.player.primary ?? "2d397a"}`,
              border: `1px solid #${teamColor.player.border ?? "eee"}`,
              color: `#${teamColor.player.number}`,
            }
      }
    >
      {player.number}
    </div>
    <span className="text-white text-xs mt-1 max-w-[80px] font-medium text-center truncate">
      {player.name}
    </span>
  </div>
);

export default TeamLineUp;
