import React from "react";
import TeamLineUp from "./TeamLineUp";
import Button from "./Button";
import { cn } from "src/lib/utils";
import { TbLoader } from "react-icons/tb";
import { Club } from "src/types";

export interface LineUpProps {
  teams: {
    home: Club;
    away: Club;
  };
  injuries: any[];
  isPending?: boolean;
}

const LineUpComponent: React.FC<LineUpProps> = ({
  teams,
  isPending,
  injuries,
}) => {
  const teamsList = [
    {
      id: 1,
      name: "Home Team",
      component: <TeamLineUp injuries={injuries} team={teams?.home} />,
    },
    {
      id: 2,
      name: "Away Team",
      component: <TeamLineUp injuries={injuries} team={teams?.away} />,
    },
  ];

  const [activeTeam, setActiveTeam] = React.useState(teamsList[0]);

  if (isPending) {
    return (
      <div className="flex items-center justify-center">
        <TbLoader size={40} className="mr-1.5 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full hidden xl:flex xl:gap-0 lg:gap-x-5">
        {teamsList.map((team) => (
          <div key={team.id} className="xl:w-1/2 flex justify-center">
            {team.component}
          </div>
        ))}
      </div>

      <div className="xl:hidden w-full flex flex-col gap-x-10 items-center justify-center px-2">
        <div className="grid grid-cols-2 py-4 w-full">
          {teamsList.map((team) => (
            <Button
              key={team.name}
              onClick={() => setActiveTeam(team)}
              className={cn(
                "col-span-1 !w-full border-none",
                activeTeam.id === team.id
                  ? "!bg-white !text-primary"
                  : "!bg-transparent !text-white"
              )}
            >
              {team.name}
            </Button>
          ))}
        </div>

        {activeTeam.component}
      </div>
    </>
  );
};

export default LineUpComponent;
