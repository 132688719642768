import { useQuery } from "@tanstack/react-query";
import { getMethod } from "src/services/request-methods";
import AchievementCard from "./AchievementCard";
import PerformanceCard from "./PerformanceCard";
import Modal from "./Modal";
import { useState } from "react";
import { Badge } from "src/types";
import dayjs from "dayjs";
import { IoClose } from "react-icons/io5";

const UserBio = ({ id }: { id: string }) => {
  const [selectedBadge, setSelectedBadge] = useState<Badge>({});
  const [badgeId, setBadgeId] = useState<string | null | undefined>(null);
  const [params, setParams] = useState({ page: 1, size: 10, isRanked: "no" });
  const [show, setShow] = useState(false);
  const { data, isFetched } = useQuery({
    queryKey: [
      "userBio",
      {
        route: "/user/badges?page=1&size=10",
        params: {},
      },
    ],
    queryFn: getMethod,
  });

  const { data: user } = useQuery({
    queryKey: [
      "user",
      {
        route: `/users/${id}`,
        params: {},
      },
    ],
    queryFn: getMethod,
    enabled: !!id,
  });

  const badgeQuery = useQuery({
    queryKey: [
      "userBadge",
      {
        route: `/user/badges/${badgeId}/logs

`,
        params: params,
      },
    ],
    queryFn: getMethod,
    enabled: !!badgeId,
  });

  const metrics = [
    { title: "Total Predictions Made", rate: user?.totalPredictions ?? 0 },
    // { title: "Success Rate", rate: 8 },
    // { title: "Performance Over Time ", rate: 5 },
    { title: "Total Points", rate: user?.totalPoints ?? 0 },
    {
      title: "Average Points per Prediction",
      rate: user?.averagePointsPerPredictions ?? 0,
    },
    // { title: "Favourite Team", rate: 5, isTeam: true },
  ];

  const handleView = (badge: Badge) => {
    setShow(true);
    setSelectedBadge(badge);

    if (badge.rankId) {
      setBadgeId(badge.rankId);
      setParams({ ...params, isRanked: "yes" });
    } else {
      setBadgeId(badge.badgeId);
      setParams({ ...params, isRanked: "no" });
    }
  };

  return (
    <div className="w-full">
      {/* <h5> Bio</h5>
      <p className="text-[#ECF9F0]/80 text-base mb-10">
        Lorem ipsum dolor sit amet consectetur. Ac dignissim iaculis enim est.
        Sed pharetra feugiat integer at proin. In venenatis commodo pretium
        aenean ut dictum faucibus erat. Ac pharetra sed cursus habitasse egestas
        amet aliquam.
      </p> */}

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4 mb-6">
        {metrics.map((item, index) => (
          <PerformanceCard {...item} key={index} />
        ))}
      </div>
      {isFetched && (
        <AchievementCard badges={data?.badges} {...{ handleView }} />
      )}
      <Modal
        title=""
        onClose={() => {
          setSelectedBadge({});
          setShow(false);
        }}
        isOpen={show}
      >
        <div className="flex justify-center gap-4 mt-2 p-5 border border-white rounded-md relative">
          <div
            onClick={() => {
              setSelectedBadge({});
              setShow(false);
            }}
            className="absolute top-2 right-2 cursor-pointer"
          >
            <IoClose />
          </div>
          {badgeQuery.isPending ? (
            <p>Loading...</p>
          ) : (
            <div className="flex flex-col justify-center items-center gap-2 relative  lg:w-[250px] w-[244px]">
              <img
                src={selectedBadge?.iconUrl}
                alt="Badge"
                className="size-40 object-contain"
              />
              <p className="text-2xl font-semibold">
                <span>{selectedBadge.badgeName}</span>
              </p>

              {badgeQuery.data?.badges.map((badge: Badge, index: number) => (
                <div
                  className="flex justify-between gap-2 w-full border-b mt-3 pb-2"
                  key={badge.badgeId}
                >
                  {selectedBadge.badgeRank && (
                    <p>{dayjs(badge.awardedAt).format("MMMM YYYY")}</p>
                  )}
                  {!badge.badgeRank && (
                    <p>
                      {badge.round
                        ? `Round ${badge.round}`
                        : `Rank ${badge.badgeRank}`}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UserBio;
